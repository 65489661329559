<template>
    <div>
        <div class="uploadContent" @click="uploadMethod">
            <img :src="imageUrl" alt="" v-if="imageUrl">
            <i class="el-icon-plus" v-else></i>
        </div>
        <input ref='upalod' id="upload" type="file" class="file" accept="image/*" @change="handleSuccess" />
    </div>
</template>
<script>
    import api from "../../http/api";
    export default {
        props: ['uploadImg',],
        data() {
            return {
                imageUrl: this.uploadImg
            }
        },
        watch: {
            uploadImg: {
                handler(nval, oval) {
                    //console.log('uploadImg--->',nval)
                    if (nval) {
                      this.imageUrl=nval
                    }
                }
            }
         },
        methods: {
            uploadMethod() {
                //console.log('ffff')
                const input = document.querySelector('#upload')
                input.click()
            },
            //成功上传后
            handleSuccess(e) {
                let that=this;
                //console.log('handleSuccess--->', e.target.files)
                const formData = new FormData()
                formData.append('file', e.target.files[0])
                api.uploadImg(formData).then(res => {
                     //console.log('热搜',res)
                     if(res.code==200){
                        that.imageUrl=res.response.data.url
                     }
                })
            },
        }
    }
</script>
<style lang="less" scoped>
    .uploadContent {
        width: 100px;
        height: 100px;
        border: 1px solid #ddd;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        img{
            width:100%;
            height:100%;
            display:block;
        }
    }

    .file {
        display: none;
    }
 
</style>